import React from "react";
import Header from "../../component/Header/Header";
import Form from "./Form/Form";
import en from "./en.json";
import css from "./Register.module.css";
import logo from "../../images/5v5-logo.svg";
import imgWhiteStrip from "../../images/white-strip.svg";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const Register = () => {
    return (
        <>
            <div className={css.container}>
                <div className={css.headerContainer}>
                    <Header />
                </div>
                <div className={css.body}>
                    <div className={css.strips} />
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className={css.logo}>
                                    <a href="/">
                                        <img alt="" src={logo} />
                                    </a>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className={css.topBlock} />
                                <div className={css.textContainer}>
                                    <div className={css.text} dangerouslySetInnerHTML={{ __html: en.text }} />
                                    <img alt="" className={css.imgWhiteStrip} src={imgWhiteStrip} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_KEY}>
                        <Form />
                    </GoogleReCaptchaProvider>
                </div>
            </div>
        </>
    );
};

export default Register;
